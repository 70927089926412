import Form from '@/components/form';
import AsyncLoadingButton from '@/components/form/asyncLoading/asyncLoadingButton';
import FormTextField from '@/components/form/fields/textField';
import { convertOrder, getInvoices } from '@/pages/api/processor/manage/quickbooks';
import useUserInfo from '@/providers/auth/useUserInfo';
import { ResponsiveModalContainer } from '@/providers/modal/responsiveModal';
import { gql } from '@apollo/client';
import { Receipt as ReceiptIcon } from '@mui/icons-material';
import { Button, Collapse, Paper, Stack, Step, StepContent, StepLabel, Stepper, Typography } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { Fragment, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { mutateGraphQL, useGraphQL } from '../../../../data';
import { CompanyWrite } from '../../../../data/company.graphql';
import { LocationsRead } from '../../../../data/management/location.graphql';
import {
	Gateway,
	Location,
	MutationCompanyWriteArgs,
	MutationInvoicesWriteArgs,
	QueryLocationsReadArgs,
} from '../../../../types/schema';
import LocationSelect from '../../../formSelects/locationSelect';

export default function SyncQBInvoices( { gateway }: { gateway: Gateway } ) {
	const [ step, setStep ] = useState( 0 );
	const [ selectedIndex, setSelectedIndex ] = useState<number>( 0 );
	
	const { staff } = useUserInfo();
	const queryClient = useQueryClient();
	const { t } = useTranslation();
	
	const { data: locationsReadData } = useGraphQL<QueryLocationsReadArgs>( {
		query    : LocationsRead,
		queryKey : [ 'locationsRead' ],
		variables: {
			options: {
				limit : 1,
				filter: {
					gateway: {
						external: 'CLOVER', active: true,
					},
				},
			},
		},
	}, { keepPreviousData: true } );
	
	const syncQBInvoices = async ( companyLocation: Location, invoiceId?: string ) => {
		const qbInvoices = invoiceId
			? await getInvoices( { gateway, ids: [ invoiceId ] } )
			: await getInvoices( { gateway, page: 0, size: 1 } );
		
		const { data } = qbInvoices;
		if ( !data.length ) return;
		
		const newInvoices = data
			.map( ( item ) => convertOrder( { externalId: gateway.externalId! }, {
				...item,
				companyLocation: companyLocation,
			} ) )
			.filter( Boolean );
		
		if ( !newInvoices.length ) return;
		await mutateGraphQL<MutationInvoicesWriteArgs>( {
			mutation : gql`
				mutation QbInvoicesSync_8485($inputs: [OrderValidator!]) {
					qbInvoicesSync(inputs: $inputs)
				}
			`,
			variables: { inputs: newInvoices },
		} );
		if ( !staff.company?.metadata?.qbInvoices ) {
			await mutateGraphQL<MutationCompanyWriteArgs>( {
				mutation : CompanyWrite,
				variables: {
					input: {
						id      : staff.company.id,
						metadata: { qbInvoices: true },
					},
				},
			} );
			await queryClient.invalidateQueries( [ 'user' ] );
		}
		setStep( 2 );
	};
	
	const actionItems = [ {
		label      : 'Import Single Invoice',
		description: 'Import QuickBooks Invoice by Invoice ID',
		buttonText : 'Import Invoice',
		onClick    : async ( location: Location, invoiceId: string ) => await syncQBInvoices( location, invoiceId ),
	}, {
		label      : t( 'settings:sync-all-invoices' ),
		description: t( 'commerce:import-new-invoices-quickbooks' ),
		buttonText : t( 'settings:sync-invoices' ),
		onClick    : async ( location: Location ) => await syncQBInvoices( location ),
		
	} ];
	
	return (
		<ResponsiveModalContainer title={t( 'commerce:sync-qb-invoices' )}>
			<Stepper
				orientation='vertical'
				activeStep={step}
				sx={{ '.MuiPaper-root': { bgcolor: 'background.default' } }}>
				<Step key={0}>
					<StepLabel>
						<Typography variant='h5'>
							{t( 'settings:sync-qb-invoices' )}
						</Typography>
					</StepLabel>
					<StepContent>
						<Form
							initialValues={{
								companyLocation: locationsReadData.locationsRead?.items?.[ 0 ] || null,
								invoiceId      : null,
							}}
							onSubmit={() => null}>
							{( formik ) => {
								const location = formik.values.companyLocation;
								const invoiceId = formik.values.invoiceId;
								
								return (
									<Fragment>
										<LocationSelect
											onlyGateway
											name='companyLocation'
											label={t( 'common:assign-to' )}
											sx={{ mt: 2, m: { sm: 2 }, maxWidth: 400 }}
											onAdd={undefined}
										/>
										<Collapse in={Boolean( location )}>
											<Stack spacing={1} my={2}>
												{actionItems.map( ( item, index ) => (
													<Paper
														key={index}
														sx={{
															'p'           : 2,
															'bgcolor'     : 'background.default',
															'borderRadius': 3,
															'border'      : 1,
															'transition'  : '.2s all',
															'borderColor' : index === selectedIndex ? 'primary.main' : 'divider',
															':hover'      : {
																cursor     : 'pointer',
																borderColor: 'primary.main',
															},
														}}
														onClick={() => setSelectedIndex( index )}>
														<Stack>
															<Typography variant='h5'>
																{item.label}
															</Typography>
															<Typography color='text.secondary'>
																{item.description}
															</Typography>
														</Stack>
														<Collapse in={selectedIndex === index}>
															<Stack spacing={1} sx={{ p: 1 }}>
																{index === 0 && (
																	<FormTextField
																		fullWidth
																		name='invoiceId'
																	/>
																)}
																<AsyncLoadingButton
																	variant='contained'
																	color='primary'
																	startIcon={<ReceiptIcon/>}
																	sx={{ alignSelf: 'end' }}
																	disabled={index === 0 && !invoiceId}
																	onClick={async () => await item.onClick( location, invoiceId )}
																	onSettled={() => null}>
																	{item.buttonText}
																</AsyncLoadingButton>
															</Stack>
														</Collapse>
													</Paper>
												) )}
											</Stack>
										</Collapse>
									</Fragment>
								);
							}}
						</Form>
					</StepContent>
				</Step>
				<Step key={2}>
					<StepLabel>
						<Typography variant='h5'>{t( 'common:done' )}</Typography>
					</StepLabel>
					<StepContent>
						<Button variant='outlined' onClick={() => setStep( 1 )}>
							{t( 'common:back' )}
						</Button>
						<Typography my={1}>
							{t( 'commerce:you-re-all-set' )}
						</Typography>
					</StepContent>
				</Step>
			</Stepper>
		</ResponsiveModalContainer>
	);
}
